import React, { Component } from 'react';
import { flow } from 'lodash/fp';
import { connect } from 'react-redux';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles'

import { isType } from '../data/queries';
import { withSelectors, records } from '../data/selectors';

import TopBar from '../components/TopBar';
import SleepDurationsChart from '../components/SleepDurationsChart';
import SleepTrendsChart from '../components/SleepTrendsChart';
import { Paragraph } from '../components/Typography';
import withLoading from '../components/withLoading';

const styles = theme => ({
  tabs: {
    marginBottom: theme.spacing(1),
  },
});

class SleepTrends extends Component {

  state = {
    selectedTab: 0,
  }

  onTabChanged = (_, selectedTab) => {
    this.setState({ selectedTab });
  }

  render() {
    console.log('SleepTrends', this.props);
    const { records, classes } = this.props;
    const { selectedTab } = this.state;

    const sleeps = records.filter(isType('sleep'));

    return (
      <React.Fragment>

        <TopBar>Sleep Trends</TopBar>

        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={this.onTabChanged}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"

        >
          <Tab label="Duration" />
          <Tab label="Trends" />
        </Tabs>
        
        { selectedTab === 0 && this.renderDuration(sleeps) }
        { selectedTab === 1 && this.renderTrends(sleeps) }

      </React.Fragment>
    );
  }

  renderDuration(sleeps) {
    return (
      <React.Fragment>
        <SleepDurationsChart records={sleeps} width="100%" height="70%" />
        <Paragraph variant='body2'>
          This chart shows the duration of every sleep so you can use your eyes to look for clustering.
         </Paragraph>
      </React.Fragment>
     );
  }

  renderTrends(sleeps) {
    return (
      <React.Fragment>
        <SleepTrendsChart records={sleeps} width="100%" height="70%" />
        <Paragraph variant='body2'>
          This chart show sleep trends so you can spot regressions and explain why you're so tired.
         </Paragraph>
      </React.Fragment>
     );
  }
}

export default flow([
  withLoading(props => props.records === null),
  withStyles(styles),
  connect(withSelectors({ records })),
])(SleepTrends);
